import React, { useState, useRef, useEffect, ReactNode } from "react";

export enum TextColors {
  WHITE = "#ffffff",
  GRAY = "#808080",
}

export enum TextSizes {
  EXTRASMALL = "10px",
  SMALL = "12px",
  MEDIUM = "18px",
  LOGO = "28px",
  LARGE = "36px"
} 

export enum Fonts {
  MONO = "'AndaleMono', monospace",
  AUDIOWIDE = "Audiowide",
  GT = "GT America"
} 

export enum TextAlign {
  CENTER = "center",
  LEFT = "left"
}

export const textDefaults = {
  color: TextColors.WHITE,
  fontFamily: Fonts.MONO,
  fontSize: TextSizes.SMALL,
  textAlign: TextAlign.CENTER
}

const Text = ({ children, color, font, size, align, scale }: { children: ReactNode, color?: TextColors, font?: Fonts, size?: TextSizes, align?: TextAlign, scale?: number }) => {
  return (
    <div style={{
      fontFamily: font ?? textDefaults.fontFamily,
      color: color ?? textDefaults.color,
      fontSize: scale !== undefined ? `min(${size ?? textDefaults.fontSize}, ${scale}vw)` : (size ?? textDefaults.fontSize),
      textAlign: align ?? textDefaults.textAlign
    }}>
      {children}
    </div>
  );
};

export default Text;
