import React, { useState, useRef, useEffect } from "react";

const Space = ({ px }) => {
  return (
    <div style={{
      marginTop: px + "px"
    }} />
  )
};

export default Space;
