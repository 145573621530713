import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import "./LandingPage.css";
import Text, { TextSizes } from "./components/Text";
import Space from "./components/Space";

const LandingPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const firstDivRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  
  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/cats-did-911.jpg)`,
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Text size={TextSizes.LARGE}>
        they know what they did.
      </Text>
      <Space px={30} />
      <Text size={TextSizes.LARGE}>
        CA: 6t6sUvK1FwC9iC4VPCvAEX22eE6iRheiW91FxN2Laq58
      </Text>
      <div style={{ position: "fixed", bottom: "10px" }}>
        <Text size={TextSizes.SMALL}>
          $CATS is simply a meme coin with no intrinsic value or expectation of financial return. Invest at your own risk.
        </Text>
      </div>
    </div>
  )
};

export default LandingPage;
